import styles from '@/styles/Modals/dialog.module.scss';
import React, { useContext } from 'react';
import TerminalButton from '../Buttons/TerminalButton';

import { ModalContext } from '@/utils/ModalContext';
import { dialogModalInterface } from '../Interfaces/CommonInterfaces';
import ErrorIconSvg from '../SVG/ErrorIconSvg';
import SuccessIconSvg from '../SVG/SuccessIconSvg';
import WarningIconSvg from '../SVG/WarningIconSvg';

interface ModalContentProps extends dialogModalInterface {}

const DialogModals: React.FC<ModalContentProps> = ({
  type = 'success',
  title = 'Empty Values',
  message = 'You Need to select at least one  <span>location</span> to save data',
  action,
  label,
  icon,
  dark = false,
}) => {
  const { closeModal } = useContext(ModalContext);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainContainer_mainDialog}>
        <div
          className={styles.mainContainer_mainDialog_icon}
          style={{
            backgroundColor:
              type === 'success'
                ? '#EBF5F2'
                : type === 'error'
                ? '#FFEEEF'
                : '#FEF6E8',
          }}
        >
          {type === 'success' ? (
            <SuccessIconSvg width={40} height={40} />
          ) : type === 'error' ? (
            <ErrorIconSvg width={40} height={40} />
          ) : (
            <WarningIconSvg width={40} height={40} />
          )}
        </div>
        <div className={styles.mainContainer_mainDialog_texts}>
          <h1>{title}</h1>
          <p
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          ></p>
        </div>
        <div className={styles.mainContainer_mainDialog_button}>
          <TerminalButton
            label={label ? label : 'ok'}
            position="ts"
            width="100%"
            onClick={() => {
              closeModal();
              action && action();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DialogModals;
