import api from '@/services/api';
import { useLoading } from '@/utils/LoadingContext';
import { ModalContext } from '@/utils/ModalContext';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import React, { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Swal from 'sweetalert2';
import DialogModalsDark from './Modals/DialogModalsDark';

const SupportAction = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (b: boolean) => void;
}) => {
  const { data, status } = useSession();
  const [screenshot, setScreenshot] = useState<FileList | null>(null);
  const theme = useTheme();

  const { loading, setLoadingState } = useLoading();
  const handleClose = () => {
    setOpen(false);
  };
  const { openModal } = useContext(ModalContext);
  const [selectedFiles, setSelectedFiles] = useState([]);
  console.log(screenshot, 'screenshot');

  const router = useRouter();

  const fileHandler = (e: any) => {
    const uploadLength = e.target.files.length;
    const length = selectedFiles.length;

    if (length < 11 && uploadLength + length < 11) {
      //@ts-ignore
      let fileArray = []; //@ts-ignore
      let array = []; //@ts-ignore
      Array.from(e.target.files).map((file) => {
        array.push(file);
        fileArray.push({
          //@ts-ignore
          name: file.name, //@ts-ignore
          url: URL.createObjectURL(file),
        });
      });
      //@ts-ignore
      setSelectedFiles((prevImages) => prevImages.concat(fileArray));

      Array.from(e.target.files).map(
        //@ts-ignore
        (file) => URL.revokeObjectURL(file) // avoid memory leak
      ); //@ts-ignore
      setScreenshot(array);
    } else {
      //@ts-ignore
      let fileArray = []; //@ts-ignore
      let array = [];
      Array.from(e.target.files).map((file) => {
        array.push(file);
        fileArray.push({
          //@ts-ignore
          name: file.name, //@ts-ignore
          url: URL.createObjectURL(file),
        });
      }); //@ts-ignore
      setSelectedFiles((prevImages) => prevImages.concat(fileArray));

      Array.from(e.target.files).map(
        //@ts-ignore
        (file) => URL.revokeObjectURL(file) // avoid memory leak
      ); //@ts-ignore
      setScreenshot(array);
    }
  };

  const handlesubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoadingState(true);
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());
    const { title, message } = formJson;
    const formDAtas = new FormData();

    if (status === 'authenticated') {
      formJson.user = data?.user._id || '';
    }
    formDAtas.append('data', JSON.stringify(formJson));
    if (screenshot) {
      for await (const element of screenshot) {
        formDAtas.append('picture', element);
      }
    }
    const postData = await api.post('/admin/support-enquiry', formDAtas);
    if (postData.data.message === 'success') {
      handleClose();
      Swal.fire({
        title: 'Successfully Sent Support Request',
        text: `One of our Representative will reach out to you soon.`,
        icon: 'success',

        confirmButtonColor: '#d12d34',

        background: '#2C2C2E',
        color: '#f2f2f2',
        confirmButtonText: 'ok',
        customClass: {
          confirmButton: 'swal-delete-button-confirm',
          cancelButton: 'red',
        },
      }).then((result) => {});
    } else {
      handleClose();
      openModal(
        <DialogModalsDark
          action={() => {}}
          title="Error!"
          type="error"
          label="Continue"
          message={`Failed To Sent Support Request Currently`}
        />
      );
    }
    // handleClose();
    setLoadingState(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();

          handlesubmit(event);
        },
        style: {
          backgroundColor: isMobile ? '#2C2C2E' : '#fff',
          color: isMobile ? '#f2f2f2' : '#000',
          borderRadius: '8px', // Dialog border-radius
        },
      }}
    >
      <DialogTitle
        style={{ color: isMobile ? '#f2f2f2' : '#000', fontSize: '16px' }}
      >
        How can we help you?
      </DialogTitle>
      <DialogContent>
        {status === 'unauthenticated' && (
          <TextField
            label="Email"
            name="email"
            fullWidth
            margin="dense"
            InputLabelProps={{
              style: { color: isMobile ? '#f2f2f2' : '#000' },
            }}
            InputProps={{
              style: {
                // backgroundColor: '#131315', // Background color
                color: isMobile ? '#f2f2f2' : '#000',
                borderRadius: '8px',
                borderColor: '#131315', // Border color
              },
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#131315', // Border color for default state
                },
                '&:hover fieldset': {
                  borderColor: '#131315', // Border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#131315', // Border color when focused
                },
              },
            }}
          />
        )}
        <TextField
          label="Title"
          name="title"
          fullWidth
          margin="dense"
          InputLabelProps={{ style: { color: isMobile ? '#f2f2f2' : '#000' } }}
          InputProps={{
            style: {
              // backgroundColor: '#131315', // Background color
              color: isMobile ? '#f2f2f2' : '#000',
              borderRadius: '8px',
              borderColor: '#131315', // Border color
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#131315', // Border color for default state
              },
              '&:hover fieldset': {
                borderColor: '#131315', // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: '#131315', // Border color when focused
              },
            },
          }}
        />
        <TextField
          label="Message"
          name="message"
          fullWidth
          multiline
          rows={3}
          margin="dense"
          InputLabelProps={{ style: { color: isMobile ? '#f2f2f2' : '#000' } }}
          InputProps={{
            style: {
              // backgroundColor: '#131315', // Background color
              color: isMobile ? '#f2f2f2' : '#000',
              borderRadius: '8px',
              borderColor: '#131315', // Border color
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#131315', // Border color for default state
              },
              '&:hover fieldset': {
                borderColor: '#131315', // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: '#131315', // Border color when focused
              },
            },
          }}
        />
        <div>
          <input
            accept="image/*"
            id="screenshot-input"
            type="file"
            style={{ display: 'none' }}
            onChange={fileHandler}
          />
          <label htmlFor="screenshot-input">
            <IconButton component="span" color="primary">
              <AddPhotoAlternateIcon />
            </IconButton>
            Add Screenshot
          </label>
          {
            //@ts-ignore
            screenshot && //@ts-ignore
              screenshot.map((file: any, index: number) => (
                <img
                  key={index}
                  src={URL.createObjectURL(file)}
                  alt="screen-shotimage"
                  width={100}
                  height={100}
                />
              ))
          }
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{ color: isMobile ? '#f2f2f2' : '#000' }}
          variant="text"
        >
          Cancel
        </Button>
        <Button type="submit" color="primary" variant="outlined">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SupportAction;
