import ThreePIcon from '@mui/icons-material/ThreeP';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import SupportAction from './SupportAction';
const SupportFAB = () => {
  const [open, setOpen] = useState(false);
  return (
    <Box
      sx={{
        '& > :not(style)': { m: 1 },
        right: '8px',
        position: 'fixed',
        bottom: isMobile ? '80px' : '40px',
      }}
    >
      <Fab
        size="medium"
        aria-label="support"
        sx={{
          background: isMobile ? '#505053' : '#d12d34',
          '&:hover': {
            background: isMobile ? '#505053' : '#d12d34',
          },
          color: isMobile ? '#f2f2f2' : '#f2f2f2',
        }}
        onClick={() => setOpen(!open)}
      >
        <ThreePIcon />
      </Fab>
      {open && <SupportAction open={open} setOpen={setOpen} />}
    </Box>
  );
};

export default SupportFAB;
